var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.loading, "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _vm.actionType ===
                              _vm.actionTypes.REVOKE_INVITATION ||
                            _vm.actionType === _vm.actionTypes.REVOKE_REQUEST ||
                            _vm.actionType === _vm.actionTypes.REJECT_REQUEST
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        color: "error",
                                      },
                                    },
                                    { ...tooltip, ...dialog }
                                  ),
                                  [_c("v-icon", [_vm._v("clear")])],
                                  1
                                )
                              : _vm.actionType ===
                                _vm.actionTypes.APPROVE_REQUEST
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        small: "",
                                        color: "success",
                                        icon: "",
                                      },
                                    },
                                    { ...tooltip, ...dialog }
                                  ),
                                  [_c("v-icon", [_vm._v("done")])],
                                  1
                                )
                              : _vm.actionType ===
                                _vm.actionTypes.RESEND_INVITATION
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    { attrs: { small: "", icon: "" } },
                                    { ...tooltip, ...dialog }
                                  ),
                                  [_c("v-icon", [_vm._v("reply")])],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v(_vm._s(_vm.actionType))])]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center secondary--text" },
              [
                _vm.actionType === _vm.actionTypes.REVOKE_INVITATION ||
                _vm.actionType === _vm.actionTypes.REVOKE_REQUEST
                  ? _c("v-icon", { staticClass: "mr-1" }, [_vm._v("clear")])
                  : _vm.actionType === _vm.actionTypes.RESEND_INVITATION
                  ? _c("v-icon", { staticClass: "mr-1" }, [_vm._v("reply")])
                  : _vm._e(),
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.actionType)),
                ]),
              ],
              1
            ),
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "mt-1" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _vm.actionType === _vm.actionTypes.REVOKE_INVITATION
                  ? _c("span", { staticClass: "subtitle-1" }, [
                      _vm._v(
                        "Are you sure you want to revoke this invitation:"
                      ),
                    ])
                  : _vm.actionType === _vm.actionTypes.REVOKE_REQUEST
                  ? _c("span", { staticClass: "subtitle-1" }, [
                      _vm._v("Are you sure you want to revoke this request:"),
                    ])
                  : _vm.actionType === _vm.actionTypes.RESEND_INVITATION
                  ? _c("span", { staticClass: "subtitle-1" }, [
                      _vm._v(
                        "Are you sure you want to re-send this invitation:"
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "v-card",
                  { staticClass: "mt-3", attrs: { flat: "", outlined: "" } },
                  [
                    _c("v-card-text", [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption font-weight-bold text-uppercase",
                            },
                            [_vm._v("requestee")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.invitationInfo.requestee_email) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption font-weight-bold text-uppercase mb-2 mt-5",
                            },
                            [_vm._v("roles")]
                          ),
                          _vm._l(
                            _vm.invitationInfo.roles,
                            function (role, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "d-flex flex-column mb-1",
                                },
                                [
                                  role.role_type ===
                                  _vm.invitationAndRequestRoleTypes
                                    .INSTANCE_ROLE
                                    ? _c(
                                        "span",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          role.Name ===
                                          _vm.roleTypes.INSTANCE_VIEWER
                                            ? _c(
                                                "v-icon",
                                                { staticClass: "mr-1 ml-1" },
                                                [_vm._v("visibility")]
                                              )
                                            : role.Name ===
                                              _vm.roleTypes.INSTANCE_EDITOR
                                            ? _c(
                                                "v-icon",
                                                { staticClass: "mr-1 ml-1" },
                                                [_vm._v("edit")]
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            " " + _vm._s(role.Instance) + " "
                                          ),
                                        ],
                                        1
                                      )
                                    : role.role_type ===
                                      _vm.invitationAndRequestRoleTypes.ORG_ROLE
                                    ? _c(
                                        "span",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1 ml-1" },
                                            [_vm._v("apartment")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(role.Organization) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    : role.role_type ===
                                      _vm.invitationAndRequestRoleTypes
                                        .SPACE_ROLE
                                    ? _c(
                                        "span",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1 ml-1" },
                                            [_vm._v("security")]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(role.Space) + " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.actionType === _vm.actionTypes.REVOKE_INVITATION ||
              _vm.actionType === _vm.actionTypes.REVOKE_REQUEST
                ? _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.loading, color: "error", text: "" },
                      on: { click: _vm.revokeInvitationOrRequest },
                    },
                    [_vm._v(" Revoke ")]
                  )
                : _vm._e(),
              _vm.actionType === _vm.actionTypes.RESEND_INVITATION
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        color: "primary",
                        text: "",
                      },
                      on: { click: _vm.resendInvitation },
                    },
                    [_vm._v("Send again")]
                  )
                : _vm._e(),
              _vm.actionType === _vm.actionTypes.APPROVE_REQUEST
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        color: "success",
                        text: "",
                      },
                      on: { click: _vm.approveRequest },
                    },
                    [_vm._v("Approve")]
                  )
                : _vm._e(),
              _vm.actionType === _vm.actionTypes.REJECT_REQUEST
                ? _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.loading, color: "error", text: "" },
                      on: { click: _vm.rejectRequest },
                    },
                    [_vm._v("Reject")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    text: "",
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }